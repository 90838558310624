<template>
  <b-container fluid>
    <div class="row bg-white">
      <div class="col-sm-12 row mt-3">
        <b-form-group class="col-sm-12">
          <label style="font-size: 20px;">{{ $t('customer_.total_to_pay') }}: {{ totalPrice }} {{ priceCurrency }}</label>
        </b-form-group>
        <b-form-group class="col-sm-12">
          <label style="font-size: 20px;">{{ $t('booking.make_payment') }}:</label><br>
          <b-form-radio class="text-uppercase" inline v-model="user.payment" v-for="(val, id) in availablePayments" :value="val" :key="id">{{ val === 'stripe' ? 'card' : val }}</b-form-radio>
        </b-form-group>
        <b-form-group class="col-sm-12" v-show="user.payment === 'cash'">
          <b-button type="submit" variant="primary" class="col-sm-12" @click="makeBooking()">{{ $t('booking.pay_cash') }}</b-button>
        </b-form-group>
        <b-form-group class="col-sm-12" v-show="user.payment === 'paypal'" id="paypalPayment">
        </b-form-group>
        <b-form-group class="col-sm-12" v-if="user.payment === 'stripe'" id="stripePayment">
          <stripe-element-card
            ref="elementRef"
            :pk="stripeUserId"
            @token="tokenCreated"
          />
          <b-button type="submit" variant="primary" class="col-sm-12" @click="payWithStripe" v-if="!paymentInProgress">{{ $t('booking.pay_stripe') }}</b-button>
        </b-form-group>
        <b-form-group class="col-sm-12" v-if="user.payment === 'revolut'" id="revolutPayment">
          <b-button type="submit" variant="primary" class="col-sm-12" @click="createRevolutApiOrder">{{ $t('booking.pay_revolut') }}</b-button>
        </b-form-group>
        <div class="col-sm-12 pt-0" v-if="paymentError" style="color: red; font-size: 13px;">{{ paymentError }}</div>
        <b-form-group class="col-sm-12 mt-5 pt-3" style="border-top: 1px solid grey">
          <b-button type="submit" variant="primary" @click="cancelBooking()">{{ $t('calendar.cancel_booking') }}</b-button>
        </b-form-group>
      </div>
    </div>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'
import { StripeElementCard } from '@vue-stripe/vue-stripe'

export default {
  name: 'BookingsList',
  components: {
    StripeElementCard
  },
  mounted () {
    core.index()
    this.getCurrentBookingInfos()
  },
  data () {
    return {
      bookingToken: this.$route.params.token || '',
      paymentError: false,
      paymentInProgress: false,
      user: {
        payment: ''
      },
      paypalPayment: '',
      availablePayments: [],
      selectedServicePrice: 0,
      totalPrice: 0,
      discountPrice: 0,
      theDates: [],
      nextWeek: true,
      previousWeek: false,
      currentWeek: '',
      center: { lat: 45.508, lng: -73.587 },
      currentPlace: null,
      categories: {
        recreation: this.$t('helper.recreation'),
        barbershop: this.$t('helper.barbershop'),
        medical: this.$t('helper.medical'),
        dentist: this.$t('helper.dentist'),
        k9: this.$t('helper.k9'),
        fitness: this.$t('helper.fitness'),
        beauty: this.$t('helper.beauty'),
        spa: this.$t('helper.spa'),
        auto: this.$t('helper.auto'),
        tattoo: this.$t('helper.tattoo')
      },
      theCategory: 'all',
      searchCompany: '',
      theRadius: 'any',
      radiuses: {
        any: this.$t('customer_.any'),
        0.5: '500m',
        1: '1km',
        2: '2km',
        3: '3km',
        5: '5km',
        10: '10km',
        20: '20km',
        50: '50km'
      },
      apiToken: localStorage.getItem('customer_api_token') || '',
      columnDefs: null,
      rowData: [],
      search: '',
      emailTo: '',
      emailText: '',
      currentUserEmail: '',
      singleMessage: '',
      optionsServices: [],
      searcheS: '',
      searche: '',
      selectedCompany: '',
      selectedCompanyName: '',
      displayCompanies: [],
      activeServices: [],
      activeServicesId: [],
      companyEmployees: [],
      selectedEmployee: '',
      companyEmployeesPictures: [],
      companyActiveEmployee: [],
      companyServices: [],
      priceCurrency: '',
      company: '',
      customerLatitude: '',
      customerLongitude: '',
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      timer: null,
      visiblePayments: false,
      selectedDay: '',
      selectedTimeStart: '',
      selectedTimeEnd: '',
      paymentPrice: '',
      paymentCurrency: '',
      selectedServiceName: '',
      revolutInstance: ''
    }
  },
  methods: {
    getCurrentBookingInfos () {
      if (this.bookingToken === '') {
        this.$router.push('/auth/login')
      } else {
        const dataPost = {
          token: this.bookingToken
        }
        Axios.post(API.API_GET_BOOKING_DETAILS, dataPost)
          .then((response) => {
            if (response.data.status === 'success') {
              // get payments
              this.totalPrice = response.data.booking.amount
              this.priceCurrency = response.data.booking.currency
              this.company = response.data.booking.company
              this.getAvailablePayments(response.data.booking.company)
            } else {
              this.$router.push('/auth/login')
            }
          })
          .catch((error) => {
            core.showSnackbar('error', error.response)
          })
      }
    },
    cancelBooking () {
      const dataPost = {
        eventToken: this.bookingToken,
        companyToken: this.company
      }
      Axios.post(API.API_DELETE_BOOKING_CUSTOMER, dataPost)
        .then(() => {
          document.location = 'onetwobooky://'
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
        })
    },
    makeBooking (transaction = null) {
      const dataPost = {
        token: this.bookingToken,
        transaction: transaction
      }
      console.log(dataPost)
      Axios.post(API.API_UPDATE_BOOKING_PAYMENT, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            alert(this.$t('booking.booking_successfull'))
            setTimeout(() => {
              document.location = 'onetwobooky://'
            }, 2000)
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
        })
    },
    getAvailablePayments (companyToken) {
      const dataPost = {
        company: companyToken
      }
      Axios.post(API.API_GET_AVAILABLE_PAYMENTS, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            if (response.data.data.available_payments) {
              this.availablePayments = response.data.data.available_payments
            }
            this.priceCurrency = response.data.data.payment_currency
            if (response.data.data.payment_test) {
              this.testPayment = response.data.data.payment_test
            }
            if (response.data.data.payment_paypal) {
              const paymentPaypal = JSON.parse(response.data.data.payment_paypal)
              if (paymentPaypal.active === true) {
                const paypalUserId = (this.testPayment === '1') ? paymentPaypal.address_test : paymentPaypal.address
                this.initializePaypal(paypalUserId)
              }
            }
            if (response.data.data.payment_revolut) {
              const paymentRevolut = JSON.parse(response.data.data.payment_revolut)
              if (paymentRevolut.active === true) {
                this.revolutUserId = (this.testPayment === '1') ? paymentRevolut.api_sandbox : paymentRevolut.api
                this.revolutInstance = (this.testPayment === '1') ? 'sandbox' : 'prod'
                this.initializeRevolut()
              }
            }
            if (response.data.data.payment_stripe) {
              const paymentStripe = JSON.parse(response.data.data.payment_stripe)
              if (paymentStripe.active === true) {
                this.stripeUserId = (this.testPayment === '1') ? paymentStripe.test_publishable : paymentStripe.live_publishable
              }
            }
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    tokenCreated (token) {
      if (token.id) {
        this.sendPaymentToStripe(token)
      }
    },
    payWithStripe () {
      this.paymentError = false
      this.paymentInProgress = true
      this.$refs.elementRef.submit()
      setTimeout(() => {
        this.paymentInProgress = false
      }, 2000)
    },
    sendPaymentToStripe (token) {
      const dataPost = {
        token: token,
        currency: this.priceCurrency,
        amount: this.totalPrice,
        company: this.company
      }
      Axios.post(API.API_SET_STRIPE_ORDER, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.makeBooking(response.data.response)
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            this.paymentError = error.response.data.message
            this.paymentInProgress = false
          } else if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    initializeRevolut () {
      const script1 = document.createElement('script')
      script1.text = '!function(e,o,t){e[t]=function(n,r){var c={sandbox:"https://sandbox-merchant.revolut.com/embed.js",prod:"https://merchant.revolut.com/embed.js",dev:"https://merchant.revolut.codes/embed.js"},d=o.createElement("script");d.id="revolut-checkout",d.src=c[r]||c.prod,d.async=!0,o.head.appendChild(d);var s={then:function(r,c){d.onload=function(){r(e[t](n))},d.onerror=function(){o.head.removeChild(d),c&&c(new Error(t+" is failed to load"))}}};return"function"==typeof Promise?Promise.resolve(s):s}}(window,document,"RevolutCheckout");'
      document.head.appendChild(script1)
    },
    createRevolutApiOrder () {
      const dataPost = {
        amount: this.totalPrice * 100,
        currency: this.priceCurrency,
        userId: this.revolutUserId,
        instanceType: this.revolutInstance
      }

      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SET_REVOLUT_ORDER, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            if (response.data.response.public_id) {
              this.setRevolutLoaded(response.data.response.public_id, this.revolutInstance, response.data.response)
            } else {
              core.showSnackbar('error', this.$t('booking.check_api_key'))
            }
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    setRevolutLoaded (apiKey, instanceType, theResponse) {
      window.RevolutCheckout(apiKey, instanceType).then(function (instance) {
        instance.payWithPopup({
          locale: 'en',
          onSuccess () {
            this.makeBooking(theResponse)
          },
          onError (message) {
            core.showSnackbar('error', message)
          }
        })
      })
    },
    initializePaypal (userId) {
      const theScript = document.getElementById('paypalScript')
      if (theScript !== null) {
        document.head.removeChild(theScript)
      }
      const script = document.createElement('script')
      script.id = 'paypalScript'
      script.src = 'https://www.paypal.com/sdk/js?client-id=' + userId + '&currency=EUR'
      script.addEventListener('load', this.setPaypalLoaded)
      document.head.appendChild(script)
    },
    setPaypalLoaded () {
      const paymentDescription = this.selectedServiceName + ' - ' + this.selectedTimeStart + ' ' + this.selectedDay
      const totalToPay = (this.priceCurrency === 'RON') ? Math.floor(this.totalPrice / 5) : this.totalPrice
      const currentInstance = this
      window.paypal.Buttons({
        onClick: function (data, actions) { },
        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: [
              {
                description: paymentDescription,
                amount: {
                  value: totalToPay
                }
              }
            ]
          })
        },
        onApprove: function (data, actions) {
          return actions.order.capture().then(function (details) {
            if (details.status === 'COMPLETED') {
              // submit the forms with the rest of the infos
              currentInstance.makeBooking(details)
            } else {
              core.showSnackbar('error', this.$t('booking.error_payment'))
            }
          })
        },
        onError: (error) => {
          core.showSnackbar('error', error)
        }
      }).render('#paypalPayment')
    },
    enablePayments () {
      this.visiblePayments = true
    }
  }
}
</script>
